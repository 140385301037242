import { apiClient } from './ApiClient';

export const OpenAIAPI = {
    getCocktailHistory: (name) => {
        return apiClient.get(`/ai/cocktail/history/${name}`);
    },
    getCocktailJoke: (name2) => {
        return apiClient.get(`/ai/cocktail/joke/${name2}`);
    },
    getCocktailInstructions: (name3) => {
        return apiClient.get(`/ai/cocktail/instructions/${name3}`);
    },
    getToast: (occasion, sentiment) => {
        return apiClient.get(`/ai/toast/${occasion}/${sentiment}`);
    }
}