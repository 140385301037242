// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSET4NEsGQlSQhG7Vjx1wPTNd5jNXGOQg",
  authDomain: "thecocktailjam.firebaseapp.com",
  projectId: "thecocktailjam",
  storageBucket: "thecocktailjam.appspot.com",
  messagingSenderId: "420703967910",
  appId: "1:420703967910:web:b8e54c4286cac4ccc37267",
  measurementId: "G-K2SKJGX22R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);