import { apiClient } from './ApiClient';

export const CocktailSearchApi = {
    getCocktailByName: (name) => {
        return apiClient.get(`/search/cocktail/${name}`);
    },
    getCocktailByIngredient: (ingredient) => {
        return apiClient.get(`/search/cocktail/ingredient/${ingredient}`);
    },
    getSearchType: (searchType) => {
        return apiClient.get(`/search/${searchType}`);
    },
    getIngredient: (ingredient) => {
        return apiClient.get(`/search/ingredient/${ingredient}`);
    }
}