import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import '../index.scss'
import { UserApi } from '../apis/UserApi';
import { CocktailSearchApi } from '../apis/CocktailSearchApi';
import { OpenAIAPI } from '../apis/OpenAIAPI';
import GalleryImg from './GalleryImg';

const CocktailDetail = () => {
    let manualSearch = false;
    const location = useLocation();

    const [locationState, setLocationState] = useState(location.state ? location.state : {cocktail: {Ingredients: []}});
    
    const [cocktailIngredients, setCocktailIngredients] = useState([]);
    // const [history, setHistory] = useState("");
    // const [joke, setJoke] = useState("");
    const [aiInstructions, setAIInstructions] = useState("");
    const [token, setToken] = useState(location.state ? location.state.token : "");
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getUserMetadata = async () => {
        const domain = "api.thecocktailjam.com";
        try {
            const accessToken = await getAccessTokenSilently({
                audience: `https://${domain}`,
                scope: "read:current_user",
            }).then((res) => {
                return res;
            });
            // console.log(accessToken);
            return accessToken;
            }
        catch (error) {
            
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // const getHistory = async (cocktailName) => {
    //     await OpenAIAPI.getCocktailHistory(cocktailName)
    //     .then(res => {
    //         setHistory(res.data.result)
    //         return res;
    //     })
    // }

    // const getJoke = async (cocktailName) => {
    //     await OpenAIAPI.getCocktailJoke(cocktailName)
    //     .then(res => {
    //         setJoke(res.data.result)
    //         return res;
    //     })
    // }

    const getInstructions = async (cocktailName) => {
        await OpenAIAPI.getCocktailInstructions(cocktailName)
        .then(res => {
            setAIInstructions(res.data.result)
            return res;
        })
    }

    useEffect(() => {
        scrollToTop();
        const mergeIngredients = (userIng) => {
            const map = new Map();
            for (const ingredient of userIng){
                map.set(ingredient.id, ingredient);
            }
            if (!location.state.cocktail.Ingredients){
                location.state.cocktail.Ingredients = userIng;
            }
            for (const ingredient of location.state.cocktail.Ingredients){
                ingredient.missing = !map.has(ingredient.id);
            }
            setCocktailIngredients(location.state.cocktail.Ingredients);
        }

        const getIngredients = (token) => {
            let userIngredients = []
            UserApi.getUserIngredients(token)
            .then(res => {
                userIngredients = res.data.result;
            })
            .then(() => {
                mergeIngredients(userIngredients, location.state.cocktail.Ingredients);
            })
        }

        if (!location.state){
            manualSearch = true;
            let urlCocktailName = location?.pathname?.split('/')[2]?.toLowerCase();
            CocktailSearchApi.getCocktailByName(urlCocktailName)
            .then(res => {
                let use = res.data.result[0];
                for (let i = 0; i < res.data.result.length; i++){
                    if (res.data.result[i].name.toLowerCase() == urlCocktailName){
                        use = res.data.result[i];
                    }
                }
                location.state = {cocktail: use};
                setLocationState(location.state);
            })
        }
        if (isAuthenticated) {
            if (manualSearch){
                getUserMetadata()
                .then(res => {
                    setToken(res);
                })
                .then(() => {
                    if (token != ""){
                        getIngredients(token);
                    }
                })
            }else{
                getIngredients(token);
            }
        }else{
            if (!manualSearch){
                setCocktailIngredients(location.state.cocktail.Ingredients);
            }
        }
        
    }, [token, locationState]);

    useEffect(() => {
        if (location.state){
            let cocktailName = location.state.cocktail.name;
            // getHistory(cocktailName);
            // getJoke(cocktailName);
            getInstructions(cocktailName);
        }
    }, [locationState]);
    
    if (location.state){
        return (
            <>
                <br/><br/><br/>
                <div className='gallery full-center'>
                    <GalleryImg style={{maxWidth: "800px", margin: 'auto'}} images={[location.state.cocktail]} 
                    token={token} isFavorited={{[location.state.cocktail.name]: location.state.cocktail.favorite}}/>
                    <br/>
                    <div style={{maxWidth: "450px", margin: 'auto'}}>
                        <h2 style={{color: '#fff'}}>Instructions</h2>
                        {aiInstructions ? 
                        <p style={{color: '#fff'}}>{aiInstructions}</p> :
                        <p style={{color: '#fff'}}>Loading instructions...</p>}
                        <h2 style={{color: '#fff'}}>Ingredients</h2>
                        <ul className='ingredient-list' style={{margin: 0, padding: 0}}>
                            {cocktailIngredients.map(ingredient => (
                                <li style={{listStyle: 'none'}} key={ingredient.id}>
                                    {isAuthenticated ? (ingredient.missing ? 
                                        <div className="tt"><i style={{color: 'darkOrange'}} className="fa-solid fa-triangle-exclamation"></i>
                                            <div className="top">
                                                <h3>Missing Ingredient</h3>
                                                <p>You are missing this ingredient! If you have it, you can add it in the Cocktail Builder.</p>
                                                <i></i>
                                            </div>
                                        </div>:
                                    <i style={{color: 'green'}} className="fa-solid fa-check"></i>) : null} &nbsp;
                                    {ingredient.name} - {ingredient.CocktailIngredients.measure}
                                    <a target={'_blank'} className="echo-romeo" href={ingredient.affiliateLink}>Buy</a>
                                    <hr></hr>
                                </li>
                            ))}
                        </ul>
                        {location.state.cocktail.history && 
                        <h2 id="h2history" style={{color: '#fff'}}>History</h2>}
                        <p id="phistory">{location.state.cocktail.history}</p>
                        {location.state.cocktail.joke && 
                        <h2 id="h2joke" style={{color: '#fff'}}>Joke</h2>}
                        <p id="pjoke">{location.state.cocktail.joke}</p>
                        <br/>
                    </div>
                </div>
            </>
        )
    }else{
        return (
            <div className='gallery full-center'>
                <h1>Loading...</h1>
            </div>
        )
    }
}

export default CocktailDetail
