import React from 'react';
import './NotFound.scss';

const NotFound = () => {
    const url = window.location.href;
    return (
        <div className="not-found">
            <h1 style={{fontSize: '100px', marginBottom: '10px'}}>404</h1>
            <h2>Page not found</h2>
            <p>We're sorry, but the page you requested could not be found.</p>
            <p>The URL <strong>{url}</strong> does not exist on this server.</p>
        </div>
    );
};

export default NotFound;
