import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import '../index.scss';
import './Gallery.scss';
import { CocktailSearchApi } from '../apis/CocktailSearchApi';
import { UserApi } from '../apis/UserApi';
import { analytics } from '../utils/FirebaseAnalytics';
import { getAnalytics, logEvent } from 'firebase/analytics';
import GalleryImg from './GalleryImg';

function Gallery(props) {
    const [images, setImages] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [isFavorited, setIsFavorited] = useState({});
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [token, setToken] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [builder, setBuilder] = useState(false);

    const analytics = getAnalytics();

    const getUserMetadata = async () => {
        const domain = "api.thecocktailjam.com";
        try {
            const accessToken = await getAccessTokenSilently({
                audience: `https://${domain}`,
                scope: "read:current_user",
            }).then((res) => {
                return res;
            });
            // console.log(accessToken);
            return accessToken;
            }
        catch (error) {
            console.log("Error with getting user metadata")
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isAuthenticated){
            return;
        }
        let token = "";
        getUserMetadata()
        .then(res => {
            token = res;
            setToken(res);
        }).then(() => {
            UserApi.getUserFavorites(token)
            .then((res) => {
                if (favorites.length === 0){
                    setFavorites(res.data.result);
                }
            })
        });
    }, [token]);

    useEffect(() => {
        let type = "";
        let query = "";
        window.location.href.split("/").forEach(function(item, index){
            if (index === 4){
                type = item;
            }
            if (index === 5){
                query = item;
            }
        })

        if (type === "cocktail"){
            setBuilder(false);
            CocktailSearchApi.getCocktailByName(query)
            .then(res => {
                setImages(res.data.result);
                setLoaded(true);
                logEvent(analytics, `cocktail_search_by_${query}`);
            })
        }else if (type === "ingredient"){
            setBuilder(false);
            CocktailSearchApi.getCocktailByIngredient(query)
            .then(res => {
                setImages(res.data.result);
                setLoaded(true);
                logEvent(analytics, `cocktail_search_by_${query}`);
            })
        }else if (type === "builder"){
            setBuilder(true);
            getUserMetadata()
            .then(res => {
                setToken(res);
                if (token){
                    UserApi.buildUserCocktails(token, query)
                    .then(res => {
                        setImages(res.data.result);
                        setLoaded(true);
                        logEvent(analytics, `builder_${query}`);
                    })
                }
            })
        }else if (props.search_type !== undefined){
            setBuilder(false);
            if (props.search_type !== "favorites"){
                CocktailSearchApi.getSearchType(props.search_type)
                .then(res => {
                    setImages(res.data.result);
                    logEvent(analytics, `search_type_${props.search_type}`);
                })
                .catch(err => {
                    logEvent(analytics, `search_type_${props.search_type}_error`, {error: err});
                })
            }else{
                setImages(favorites);
            }
        }
    }, [favorites, props.search_type]);

    if ((images.length > 0)){

        const mergeFavorites = (images, builder) => {
            images.map((image, index) => {
                if (builder) {
                    image = image.cocktail;
                }
                const favorite = favorites.find(favorite => favorite.name === image.name);
                if (favorite){
                    image.favorite = true;
                }else{
                    image.favorite = false;
                }
                isFavorited[`${image.name}`] = image.favorite;
                return image;
            })
        }
        mergeFavorites(images, builder);

        return (
            <>
                <div className="container-fluid">
                    <div className="gallery row full-center">
                        <GalleryImg images={images} builder={builder} isFavorited={isFavorited} token={token}/>
                    </div>
                </div>
            </>
        )
    }else if (!loaded){
        return (
            <h1 className="loading">Loading...</h1>
        )
    }else{
        return (
            <h1 style={{textAlign: "center", color: "#fff"}}>404</h1>
        )
    }
}

export default Gallery;
