import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { UserApi } from '../../apis/UserApi';
import { CocktailSearchApi } from '../../apis/CocktailSearchApi';
import './CocktailBuilder.scss';

function CocktailBuilder() {
    const { getAccessTokenSilently } = useAuth0();
    const [ingredients, setIngredients] = useState([]);
    const [inputVal, setInputVal] = useState("");
    const [token, setToken] = useState("");

    const getUserMetadata = async () => {
        const domain = "api.thecocktailjam.com";
        try {
            const accessToken = await getAccessTokenSilently({
                audience: `https://${domain}`,
                scope: "read:current_user",
            }).then((res) => {
                return res;
            });
            return accessToken;
            }
        catch (error) {
            console.log(error);
        }
    };

    const onRemove = (ingredient) => {
        UserApi.deleteUserIngredients(token, ingredient)
        .then(res => {
            setIngredients(ingredients.filter(i => i.name !== ingredient.name));
        })
    }

    useEffect(() => {
        let search_input = document.getElementById('search-input');
        let autocom_box = document.getElementById('autocom-box');
        let wrapper = document.getElementById('wrapper');

        search_input.onkeyup = (e) => {
            let userData = e.target.value;
            let suggestions = [];
            if (userData && e.key !== "Backspace"){
                CocktailSearchApi.getIngredient(userData)
                .then(res => {
                    suggestions = res.data.result;
                    suggestions = suggestions.map((data) => {
                        return data = '<li>' + data.name + '</li>';
                    });
                    wrapper.classList.add('active');
                    showSuggestions(suggestions);
                    let allList = autocom_box.querySelectorAll('li');
                    allList.forEach((list) => {
                        list.addEventListener('click', (e) => {
                            select(e.target);
                        });
                    });
                })
            }else{
                wrapper.classList.remove('active');
            }
        }

        function select(element){
            search_input.value = "";
            let ingredient = {id: ingredients.length + 1, name: element.textContent};
            UserApi.saveUserIngredients(token, ingredient)
            .then(res => {
                setIngredients(res.data.result);
            })
            wrapper.classList.remove('active');
        }

        function showSuggestions(list){
            let listData;
            if (!list.length){
                let userValue = search_input.value;
                listData = '<li>' + userValue + '</li>';
            }else{
                listData = list.join('');
            }
            autocom_box.innerHTML = listData;
        }

        getUserMetadata()
        .then(res => {
            setToken(res);
        })
        .then(() => {
            if (token){
                UserApi.getUserIngredients(token)
                .then(res => {
                    setIngredients(res.data.result);
                })
            }
        })
    }, [token, getAccessTokenSilently]);

    return (
        <div className='cocktail-builder' style={{marginTop: '90px'}}>
            <form className='mb-5'>
                <div className='search-bar' id="wrapper">
                    <input type="text" id="search-input" style={{width: '350px'}} className={`input-search text-center`} placeholder={`Add ingredient`}>
                    </input>
                    <div id="autocom-box" className='autocom-box'>

                    </div>
                </div>
            </form>
            <h3>My Ingredients</h3>
            <ul id="ingredient-list">
                {ingredients.map(ingredient => (
                    <li key={ingredient.id}>{ingredient.name} &nbsp;<i onClick={() => onRemove(ingredient)} className="fa-solid fa-ban"></i></li>
                ))}
            </ul>
            <button id="build-cocktails" onClick={() => window.location.href=`/search/builder/${ingredients.map(ingredient => ingredient.name).join(",")}`} className="btn btn--large btn--outline">Build Cocktails</button>
        </div>
    )
}

export default CocktailBuilder;
