import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UserApi } from '../../apis/UserApi';
import './Profile.scss';

const Profile = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const { name, picture } = user;

  const getUserMetadata = async () => {
      const domain = "api.thecocktailjam.com";
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}`,
          scope: "read:current_user",
        }).then((res) => {
          return res;
        });
        return accessToken;
      } catch (e) {
        console.log(e);
      }
  };

  const confirmDelete = () => {
    let answer = window.confirm("Are you sure you want to delete your account?");
    if (answer) {
      deleteAccount();
    }
  };

  const deleteAccount = () => {
    let token = "";
    getUserMetadata()
    .then((res) => {
      token = res;
    })
    .then(() => {
      UserApi.deleteUserAccount(token)
      .then((res) => {
        logout({ returnTo: window.location.origin });
      })
    });
  };

  return (
    <section className="h-100 gradient-custom-2">
      <div className="container py-6 h-200">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="">
            <div className="card">
              <div className="rounded-top text-white d-flex flex-row" style={{backgroundColor: "#F77307", height: "200px"}}>
                <div className="ms-4 mt-5 d-flex flex-column" style={{width: "200px"}}>
                  <img src={picture}
                    alt="pfp" className="img-fluid img-thumbnail mt-4 mb-2"
                    style={{width: "150px", zIndex: 1}} />
                </div>
                <div className="" style={{marginTop: "130px"}}>
                  <p style={{color: 'black'}}>{name}</p>
                </div>
              </div>
              <div className="card-body p-4 text-black" style={{backgroundColor: "#343a40"}}>
                <div className="mb-2">
                  <p className="lead fw-normal mb-1">Account Info</p>
                  <div className="p-4" style={{backgroundColor: "#272a2e"}}> 
                    <p className="font-italic mb-1">Nickname: {user.nickname}</p>
                    <p className="font-italic mb-1">Email Verified: {user.email_verified ? "Yes" : "No"}</p>
                    <p className="font-italic mb-0">Updated at: {user.updated_at}</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-danger del" onClick={confirmDelete}>Delete Account</button>
                    <button className="btn btn-danger ms-3" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
};

export default Profile;