import { apiClient } from "./ApiClient";
import qs from 'qs';

export const UserApi = {
    getUserIngredients: (token) => {
        return apiClient.get(`/user/ingredients`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },
    saveUserIngredients: (token, ingredient) => {
        return apiClient.post(`/user/ingredients`, qs.stringify({ingredient: ingredient.name}), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        });
    },
    deleteUserIngredients: (token, ingredient) => {
        return apiClient.delete(`/user/ingredients`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            },
            data: qs.stringify({ingredient: ingredient.name}),
        });
    },
    buildUserCocktails: (token, ingredients) => {
        return apiClient.get(`/user/cocktails/${ingredients}`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            },
        });
    },
    getUserFavorites: (token) => {
        return apiClient.get(`/user/favorites`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    },
    saveUserFavorites: (token, cocktail) => {
        return apiClient.post(`/user/favorites`, qs.stringify({cocktail: cocktail.name}), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        });
    },
    deleteUserFavorites: (token, cocktail) => {
        return apiClient.delete(`/user/favorites`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            },
            data: qs.stringify({cocktail: cocktail.name}),
        });
    },
    deleteUserAccount: (token) => {
        return apiClient.delete(`/user`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
}