import React, { useState } from 'react';
import { OpenAIAPI } from '../../apis/OpenAIAPI';
import './Toast.scss';

const OCCASIONS = [
    "Choose an occasion",
    "wedding",
    "birthday",
    "graduation",
    "retirement",
    "anniversary",
    "new job",
    "new baby",
    "new home",
    "promotion",
];

const SENTIMENTS = [
    "Choose a sentiment",
    "sentimental",
    "encouraging",
    "funny",
    "emotional",
    "sincere",
];

function Toast() {
    const [selectedOccasion, setSelectedOccasion] = useState("Choose an occasion");
    const [selectedSentiment, setSelectedSentiment] = useState("Choose a sentiment");
    const [toast, setToast] = useState("");

    const handleOccasionChange = (event) => {
        setSelectedOccasion(event.target.value);
    };

    const handleSentimentChange = (event) => {
        setSelectedSentiment(event.target.value);
    };

    const getToast = () => {
        if (selectedOccasion === "Choose an occasion" || selectedSentiment === "Choose a sentiment") {
            setToast("Please choose an occasion and a sentiment.");
            return;
        }
        setToast("Loading toast...");
        OpenAIAPI.getToast(selectedOccasion, selectedSentiment)
        .then((res) => {
            console.log(res.data.result);
            setToast(res.data.result);
        })
    }

  return (
    <div className="container justify-content-center full-center" style={{marginTop: '90px'}}>
        <h1>Choose an occasion and a sentiment</h1>
        <hr/>
        <div style={{display: 'inline-flex'}}>
            <select name="occasion" className='form-select justify-content-center' value={selectedOccasion} onChange={handleOccasionChange}>
                {OCCASIONS.map((occasion) => (
                    <option key={occasion} value={occasion}>{occasion}</option>
                ))}
            </select>
            &nbsp;&nbsp;&nbsp;
            <select name="sentiment" className='form-select justify-content-center' value={selectedSentiment} onChange={handleSentimentChange}>
                {SENTIMENTS.map((sentiment) => (
                    <option key={sentiment} value={sentiment}>{sentiment}</option>
                ))}
            </select>
        </div>
        <hr/>
        <h2>Generate {selectedSentiment === "Choose a sentiment" ? "_____" : selectedSentiment} toast for {selectedOccasion === "Choose an occasion" ? "_____" : selectedOccasion}.</h2>
        <button className='btn' onClick={() => getToast()}>Generate Toast</button>
        <h4 id="result-toast" className='mt-4'>{toast}</h4>
    </div>
  );
}

export default Toast;
