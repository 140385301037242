import axios from 'axios';

const domain = "https://api.thecocktailjam.com";
const devDomain = "http://localhost";

export const apiClient = axios.create({
    baseURL: `${domain}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});