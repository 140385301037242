import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import "./SearchBar.scss";
import { CocktailSearchApi } from '../apis/CocktailSearchApi';

import './Navbar.scss';

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {setClick(false); window.scrollTo(0, 0);};
  
  const [searchType, setSearchType] = useState("cocktail");
  const [highLight, setHighLight] = useState(true);

  const [popular, setPopular] = useState(false);
  const [latest, setLatest] = useState(false);
  const [random, setRandom] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [builder, setBuilder] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const minSearchKeys = 2;
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (inputVal !== "") {
        window.location.href = `/search/${searchType}/${inputVal}`;
      }
    }
  };
  
  const toggleButton = (type) => {
    if (type === "cocktail") {
      setSearchType("cocktail");
      if (!highLight) {
        setHighLight(true);
      }
    } else {
      setSearchType("ingredient");
      if (highLight) {
        setHighLight(false);
      }
    }
  }

  const assignBrandColor = (type) => {
    if (type === "popular") {
      setPopular(true);
      setLatest(false);
      setRandom(false);
      setFavorite(false);
      setBuilder(false);
    } else if (type === "latest") {
      setPopular(false);
      setLatest(true);
      setRandom(false);
      setFavorite(false);
      setBuilder(false);
    } else if (type === "random") {
      setPopular(false);
      setLatest(false);
      setRandom(true);
      setFavorite(false);
      setBuilder(false);
    } else if (type === "favorite") {
      setPopular(false);
      setLatest(false);
      setRandom(false);
      setFavorite(true);
      setBuilder(false);
    } else if (type === "builder") {
      setPopular(false);
      setLatest(false);
      setRandom(false);
      setFavorite(false);
      setBuilder(true);
    }
  }

  const setAllFalse = () => {
    setPopular(false);
    setLatest(false);
    setRandom(false);
    setFavorite(false);
    setBuilder(false);
  }


  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    if (window.location.pathname === "/popular") {
      setPopular(true);
    } else if (window.location.pathname === "/latest") {
      setLatest(true);
    } else if (window.location.pathname === "/random") {
      setRandom(true);
    } else if (window.location.pathname === "/") {
      setRandom(true);
    } else if (window.location.pathname === "/favorites") {
      setFavorite(true);
    } else if (window.location.pathname === "/builder") {
      setBuilder(true);
    }
  }, []);

  window.addEventListener('resize', showButton);
  let avatar = null;
  if (props.isAuthenticated) {
    avatar = props.user.picture;
  }
  

  useEffect(() => {
    let search_input = document.getElementById('search-input2');
    let autocom_box = document.getElementById('autocom-box2');
    let wrapper = document.getElementById('wrapper2');

    search_input.onkeyup = (e) => {
        let userData = e.target.value;
        let suggestions = [];
        if (userData.length > minSearchKeys && e.key !== "Backspace"){
            if (searchType === "cocktail"){
              CocktailSearchApi.getCocktailByName(userData)
              .then(res => {
                  suggestions = res.data.result;
                  suggestions = suggestions.map((data) => {
                      return data = '<li>' + data.name + '</li>';
                  });
                  wrapper.classList.add('active');
                  showSuggestions(suggestions);
                  let allList = autocom_box.querySelectorAll('li');
                  allList.forEach((list) => {
                      list.addEventListener('click', (e) => {
                          select(e.target, true);
                      });
                  });
              })
            }else{
              CocktailSearchApi.getIngredient(userData)
              .then(res => {
                  suggestions = res.data.result;
                  suggestions = suggestions.map((data) => {
                      return data = '<li>' + data.name + '</li>';
                  });
                  wrapper.classList.add('active');
                  showSuggestions(suggestions);
                  let allList = autocom_box.querySelectorAll('li');
                  allList.forEach((list) => {
                      list.addEventListener('click', (e) => {
                          select(e.target, false);
                      });
                  });
              })
            }
        }else{
            wrapper.classList.remove('active');
        }
    }

    function select(element, isCocktail){
        search_input.value = "";
        window.location.href = isCocktail ? "/search/cocktail/" + element.textContent : "/search/ingredient/" + element.textContent;
    }

    function showSuggestions(list){
        let listData;
        if (!list.length){
            let userValue = search_input.value;
            listData = '<li>' + userValue + '</li>';
        }else{
            listData = list.join('');
        }
        autocom_box.innerHTML = listData;
    }

    $(document).keyup(function(e) {
      if (e.key === "Escape") {
        $('.modal-wrapper').removeClass('open');
        $('.page-wrapper').removeClass('blur-it');
      }
    });

    $('.trigger').unbind().on('click', function() {
      $('.modal-wrapper').toggleClass('open');
      $('.page-wrapper').toggleClass('blur-it');
      closeMobileMenu();
      return false;
    });
  }, [searchType]);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={
            () => {
              closeMobileMenu();
              assignBrandColor("random");
            }}>
          <img className="profile-button" src="../../favicon.ico" alt="Profile" />
            TheCocktailJam
            
          </Link>
          {button && (
            <>
          <Link
            to='/random'
            className={random ? 'nav-links brand-color' : 'nav-links'}
            onClick={() => {
              closeMobileMenu();
              assignBrandColor("random");
            }}
          >
            Random
          </Link>
          <Link
            to='/popular'
            className={popular ? 'nav-links brand-color' : 'nav-links'}
            onClick={() => {
              closeMobileMenu();
              assignBrandColor("popular");
            }}
          >
            Popular
          </Link>
          <Link
            to='/latest'
            className={latest ? 'nav-links brand-color' : 'nav-links'}
            onClick={() => {
              closeMobileMenu();
              assignBrandColor("latest");
            }}
          >
            Latest
          </Link>
          </>
          )}
          
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {!button && (
              <>
            <li className='nav-item'>
              <Link
                to='/popular'
                className={popular ? 'nav-links brand-color' : 'nav-links'}
                onClick={() => {
                  closeMobileMenu();
                  assignBrandColor("popular");
                }}
              >
                Popular
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/latest'
                className={latest ? 'nav-links brand-color' : 'nav-links'}
                onClick={() => {
                  closeMobileMenu();
                  assignBrandColor("latest");
                }}
              >
                Latest
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/random'
                className={random ? 'nav-links brand-color' : 'nav-links'}
                onClick={() => {
                  closeMobileMenu();
                  assignBrandColor("random");
                }}
              >
                Random
              </Link>
            </li>
            </>
            )}
            {props.isAuthenticated ? (
            <li className='nav-item'>
              <Link to='/favorites' className={favorite ? 'nav-links brand-color' : 'nav-links'}
              onClick={() => {
                closeMobileMenu();
                assignBrandColor("favorite");
              }}>
                Favorites
              </Link>
            </li>
            ) : (
            <li className='nav-item'>
              <a
                href='#'
                style={{cursor: 'pointer'}}
                className={favorite ? 'nav-links brand-color' : 'nav-links'}
                onClick={() => {
                  props.loginWithRedirect();
                }}>
                  Favorites
              </a>
            </li>
            )}
            <li className='nav-item dropdown'>
              {props.isAuthenticated ? (
              <>
                <a style={{cursor: 'pointer'}}
                  className={builder ? 'nav-links brand-color dropbtn' : 'nav-links dropbtn'}
                  onClick={() => {
                    closeMobileMenu();
                    assignBrandColor("builder");
                  }}
                >
                  Cocktail Tools
                </a>
                <div className='dropdown-content'>
                  <a href="/builder">Cocktail Builder</a>
                  <a href="/toast">Toast Builder</a>
                </div>
              </>
              ) : (
              <>
                <a
                  style={{cursor: 'pointer'}}
                  className={builder ? 'nav-links brand-color dropbtn' : 'nav-links dropbtn'}
                >
                  Cocktail Tools
                </a>
                <div className='dropdown-content'>
                  <a href="#" onClick={() => props.loginWithRedirect()}>Cocktail Builder</a>
                  <a href="/toast">Toast Builder</a>
                </div>
              </>
              )}
            </li>

            <li>
              {!props.isAuthenticated ? (
                <a
                  href='#'
                  className='nav-links'
                  onClick={() => props.loginWithRedirect()}
                >
                  <img className="profile-button" src="../../profile-button.jpg" alt="Profile" />
                </a>
              ) : (
                <Link
                  to='/profile'
                  className='nav-links'
                  onClick={() => {
                    closeMobileMenu();
                    setAllFalse();
                  }}
                >
                  <img className="profile-button" src={avatar} alt="Profile" />
                </Link>
              )}
            </li>
            <li className='nav-item' onClick={closeMobileMenu}>
              <i className="nav-links fas fa-search search-custom trigger"></i>
            </li>
          </ul>
        </div>
      </nav>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="head">
            <a className="btn-close trigger" href="#">
              <i aria-hidden="true"></i>
            </a>
          </div>
          <div className="content">
            <h1 style={{color: 'black'}}>Search Cocktails</h1>
            <div className='search-bar' id="wrapper2">
              <input type="text" id="search-input2" onChange={(e) => {
                setInputVal(e.target.value);
                }} onKeyDown={handleKeyDown} className={`input-search text-center`} placeholder={`Searching by ${searchType}...`}>
              </input>
              <div id="autocom-box2" className='autocom-box'>

              </div>
              <div className='hero-btns'>
              <button
                className={`btn btn--large btn--outline ${!highLight ? "highlighted_btn" : ""}`}
                onClick={() => {
                  toggleButton("ingredient");
                }
                }
              >
                Search by Ingredients
              </button>
              <button
                className={`btn btn--large btn--outline ${highLight ? "highlighted_btn" : ""}`}
                onClick={() => {
                  toggleButton("cocktail");
                }
                }
              >
                Search by Cocktail name
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
