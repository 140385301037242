import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import './index.scss';

const domain = "thecocktailjam.us.auth0.com";
const clientId = "wSgnwNG0yvVFjha7ciJksf0HcciEaf28";

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience="https://api.thecocktailjam.com"
      scope="read:current_user update:current_user_metadata"
      cacheLocation="localstorage"
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
);