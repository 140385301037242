import React, {useState, useEffect, useCallback} from 'react';
import Navbar from './components/Navbar';
import Profile from './components/pages/Profile';
import NotFound from './components/pages/NotFound';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Routes,
  Route
} from "react-router-dom";
import Gallery from './components/Gallery';
import CocktailBuilder from './components/pages/CocktailBuilder';
import CocktailDetail from './components/CocktailDetail';
import Toast from './components/pages/Toast';
import Footer from './components/Footer';
import { useLocation } from 'react-router-dom';
import { GA4React } from 'ga-4-react';

const ga4react = new GA4React('G-K2SKJGX22R').initialize();

const trackPathForAnalytics = (path) => {
  ga4react
    .then((ga4) => {
      ga4.pageview(path);
    })
    .catch((error) => {
      console.log("Analyics failed: " + error);
    });
};


function App() {
  const {pathname, search} = useLocation();
  const analytics = useCallback(() => {
    trackPathForAnalytics(pathname + search);
  }, [pathname, search]);
  useEffect(() => {
    analytics();
  }, [analytics]);
  const { loginWithRedirect, logout, isAuthenticated, user,
  isLoading, error, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  return (
    <>
      {!error && isLoading && <p>Loading...</p>}
      {!error && !isLoading && (
        <>
        <Navbar
          isAuthenticated={isAuthenticated} 
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          user={user}
        />
        <Routes>
          <Route path="/" element={<Gallery search_type={"random"}/>} />
          <Route exact path='/search/:type/:query' element={<Gallery />} />
          <Route exact path='/profile' element={<Profile />} />
          <Route exact path='/favorites' element={<Gallery search_type={"favorites"}/>} />
          <Route exact path='/builder' element={<CocktailBuilder />} />
          <Route exact path='/toast' element={<Toast />} />
          <Route exact path='/popular' element={<Gallery search_type={"popular"}/>} />
          <Route exact path='/latest' element={<Gallery search_type={"latest"}/>} />
          <Route exact path='/random' element={<Gallery search_type={"random"}/>} />
          <Route path='/cocktail/:id' element={<CocktailDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer/>
        </>
      )}
    </>
  );
}

export default App;
