import "./Footer.scss"

const Footer = (props) => {
  let year = props.year;
  if (!year) {
    year = new Date().getFullYear();
  }
  return (
    <footer className="text-white mt-5">
      <div className="container-md">
          <div className="row">
          <div className="col-md-3 col-sm-6">
              <h5>About Us</h5>
              <p>Search hundreds of drinks here at TheCocktailJam and build any drink imaginable with our cocktail builder.
                Explore and share your creations with friends!
              </p>
          </div>
          <div className="col-md-3 col-sm-6">
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
              <li><a href="/">Home</a></li>
              <li><a href="/random">Random Cocktails</a></li>
              <li><a href="/popular">Popular Cocktails</a></li>
              <li><a href="/latest">Latest Cocktails</a></li>
              </ul>
          </div>
          <div className="col-md-3 col-sm-6">
              <h5>Follow Us</h5>
              <ul className="list-unstyled">
              <li><a href=""><i className="fab fa-facebook fa-lg"></i> Facebook</a></li>
              <li><a href="https://twitter.com/TheCocktailJam" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-lg"></i> Twitter</a></li>
              <li><a href="https://www.instagram.com/thecocktailjam/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-lg"></i> Instagram</a></li>
              <li><a href="https://www.tiktok.com/@thecocktailjam" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok fa-lg"></i> TikTok</a></li>
              </ul>
          </div>
          <div className="col-md-3 col-sm-6">
              <h5>Email Us</h5>
              <address>
              <a href="mailto:help@thecocktailjam.com">help@thecocktailjam.com</a>
              </address>
          </div>
          </div>
      </div>
      <div className="">
        <div className="row">
            <div className="col-md-12">
            <p className="text-center mb-0">Copyright &copy; 2023 &nbsp;
              <a href="">ZEDA Software Studio LLC</a> &nbsp;All rights reserved.</p>
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
