import React from 'react'
import { Link } from 'react-router-dom'
import { UserApi } from '../apis/UserApi';
import {
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import $ from 'jquery';
import { useAuth0 } from '@auth0/auth0-react';

const GalleryImg = (props) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const onAddToFavorites = (cocktail) => {
        UserApi.saveUserFavorites(props.token, cocktail);
    }

    const onRemoveFromFavorites = (cocktail) => {
        UserApi.deleteUserFavorites(props.token, cocktail);
    }

    const handleFavorite = (image) => {
        if (!isAuthenticated){
            console.log("not authenticated");
            loginWithRedirect();
            return;
        }
        if (props.isFavorited[`${image.name}`] === true){
            onRemoveFromFavorites(image);
            props.isFavorited[`${image.name}`] = false;
        }else{
            onAddToFavorites(image);
            props.isFavorited[`${image.name}`] = true;
        }
        image.favorite = props.isFavorited[`${image.name}`];
        const toggle = document.getElementById(`toggle-${image.name}`);
        if (toggle.classList.contains('fa-heart-o')){
            toggle.classList.remove('fa-heart-o');
            toggle.classList.add('fa-heart');
        }else{
            toggle.classList.remove('fa-heart');
            toggle.classList.add('fa-heart-o');
        }
    };

    const favoriteLogic = (image) => {
        if (!props.handleFavorite){
            handleFavorite(image);
        }else{
            props.handleFavorite(image);
        }
    }

    const shareLogic = (index) => {
        const invis = document.getElementsByClassName('invis')[index];
        $(invis).fadeToggle();
        invis.style.display = 'block';
        const changeImg = document.getElementsByClassName('share-img')[index];
        if (changeImg.src.includes('share.png')){
            changeImg.src = '../../share-full.png';
        }else{
            changeImg.src = '../../share.png';
        }
    }

    let info = "Check out this cocktail I found: ";
    let url = window.location.href;
    let toUrl = "";
    let galleryPage = true;
    if (url.includes("/cocktail/")){
        galleryPage = false;
    }
    return (
        <>
            {props.images.map((image, index) => {
                if (props.builder || image.name === undefined) {
                    image = image.cocktail;
                }
                image.name = image.name.replaceAll(/\//g, '');
                toUrl = image.name.replaceAll(" ", "%20");
                return (
                    <div key={index} className="col-sm-6 col-lg-3 gallery-item mt-3 zoom min-width" style={props.style}>
                        <Link style={{textDecoration: 'none'}} to={`/cocktail/${image.name}`} state={{cocktail: image, token: props.token}}>
                        <div className="gallery-img-container" onClick={() => {window.scrollTo(0, 0)}}>
                            <img src={image.imageSource} className="gallery-img" alt='cocktail_picture'></img>
                            <h4 className='cocktail-name'>{image.name}</h4>
                        </div>
                        </Link>
                        <div className='invis gallery-share-icons'>
                            <FacebookShareButton style={{position: 'absolute', bottom: '14px', left: '60px'}} url={galleryPage ? `www.thecocktailjam.com/cocktail/${toUrl}` : url} quote={info + image.name}>
                                <FacebookIcon size={27} round={true}/>
                            </FacebookShareButton>
                            <TwitterShareButton style={{position: 'absolute', bottom: '14px', left: '90px'}} url={galleryPage ? `www.thecocktailjam.com/cocktail/${toUrl}` : url} title={info + image.name}>
                                <TwitterIcon size={27} round={true}/>
                            </TwitterShareButton>
                            <RedditShareButton style={{position: 'absolute', bottom: '14px', left: '120px'}} url={galleryPage ? `www.thecocktailjam.com/cocktail/${toUrl}` : url} title={info + image.name}>
                                <RedditIcon size={27} round={true}/>
                            </RedditShareButton>
                            <TelegramShareButton style={{position: 'absolute', bottom: '14px', left: '150px'}} url={galleryPage ? `www.thecocktailjam.com/cocktail/${toUrl}` : url} title={info + image.name}>
                                <TelegramIcon size={27} round={true}/>
                            </TelegramShareButton>
                            <WhatsappShareButton style={{position: 'absolute', bottom: '14px', left: '180px'}} url={galleryPage ? `www.thecocktailjam.com/cocktail/${toUrl}` : url} title={info + image.name}>
                                <WhatsappIcon size={27} round={true}/>
                            </WhatsappShareButton>
                        </div>
                        <div className='gallery-share'>
                            <img src='../../share.png' className='share-img' onClick={() => {
                                shareLogic(index);
                            }}></img>
                        </div>
                        <div className="gallery-star">
                            <i id={`toggle-${image.name}`} className={props.isFavorited[`${image.name}`] ? "fa fa-heart fa-2x" : "fa fa-heart-o fa-2x"} 
                            onClick={() => favoriteLogic(image)}></i>
                        </div>
                    </div>
                )
                }
            )}
        </>
    )
}

export default GalleryImg
